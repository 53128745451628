import React from 'react';
import twitterLogoForFeed from '../assets/twitter-background.png';

export default function TwitterFeed(props) {
  return (
    <div className="twitter-feed-container">
      <div className="twitter-logo">
        <img src={twitterLogoForFeed} alt="" />
      </div>
      <div className="twitter-feed">
        <div class="twitter-animation-block">
          <a href="<?php echo $twitter; ?>" target="_new">
            {props.tweets.map((tweet, index) => <span key={index}>{tweet} <span className="tweet-spacer"> | </span></span>)}
          </a>
        </div>
      </div>
    </div>
  );
}