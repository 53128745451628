import React, { Component } from 'react';
import { graphql } from "gatsby"
import axios from 'axios';

import LegacyContainer from '../components/legacy-container';
import banner from '../assets/dom-booth-banner.png';
import facebook from '../assets/facebook-white.png';
import twitter from '../assets/twitter-white.png';
import instagram from '../assets/instagram-white.png';
import spotify from '../assets/spotify_inv.png';
import soundcloud from '../assets/soundcloud-white.png';
import SEO from '../components/SEO';
import TwitterFeed from '../components/twitter-feed';

export default class IndexPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tweets: []
    }
  }

  componentDidMount() {
    axios.get(`https://api.dboothdj.com/twitter`).then(res => this.setState({ tweets: res.data.tweets }));
  }

  render() {
    const socialLinks = this.props.data.socials.edges[0].node.frontmatter;
    const content = this.props.data.content.edges[0].node.frontmatter;
    return (
      <LegacyContainer homePage={true}>
        <SEO />
        <div className="content-container-with-video">
          <div className="content-home">
            <div className="home-banner no-select">
              <img src={banner} alt="" />
            </div>
            <div className="home-socials no-select">
              <a href={socialLinks.facebook} target="_new"><img src={facebook} alt="" /></a><a href={socialLinks.twitter} target="_new"><img src={twitter} alt="" /></a><a href={socialLinks.instagram} target="_new"><img src={instagram} alt="" /></a><a href={socialLinks.soundcloud} target="_new"><img src={soundcloud} alt="" /></a><a href={socialLinks.spotify} target="_new"><img src={spotify} alt="" /></a>
            </div>
            <TwitterFeed tweets={this.state.tweets} />
          </div>
        </div>
        <h1 className="screen-reader-text">{ content.heading }</h1>
        <div className="background-video-container">
          <video controls autoPlay loop muted playsInline className="vid-player">
            <source src="https://api.dboothdj.com/home_2.webm" type="video/webm; codecs=vp9,vorbis" />
            <source src="https://api.dboothdj.com/home_2.mp4" type="video/mp4" />
          </video>
        </div>
      </LegacyContainer>
    );
  }
}


export const homePageSocialsQuery = graphql`
query HomePage {
  socials: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "meta-social-links" }}} limit: 1) {
    edges {
      node {
        frontmatter {
          facebook
          twitter
          instagram
          soundcloud
          spotify
        }
      }
    }
  }
  content: allMarkdownRemark(
    filter: { frontmatter: { templateKey: { eq: "home-page" } }}
    limit: 1
  ) {
    edges {
      node {
        frontmatter {
          heading
        }
      }
    }
  }
}
`